"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maxSize = exports.minSize = exports.deviceSize = void 0;
const types_1 = require("./types");
// Used to determine what sizes should be included in media queries
const sizeOrder = [
    types_1.DEVICE_SIZES.EXTRA_SMALL_DEVICE,
    types_1.DEVICE_SIZES.SMALL_DEVICE,
    types_1.DEVICE_SIZES.MEDIUM_DEVICE,
    types_1.DEVICE_SIZES.LARGE_DEVICE,
    types_1.DEVICE_SIZES.EXTRA_LARGE_DEVICE,
];
// Default breakpoint sizes
function deviceSize(width) {
    if (width > 1200)
        return types_1.DEVICE_SIZES.EXTRA_LARGE_DEVICE;
    if (width > 992)
        return types_1.DEVICE_SIZES.LARGE_DEVICE;
    if (width >= 768)
        return types_1.DEVICE_SIZES.MEDIUM_DEVICE;
    if (width > 540)
        return types_1.DEVICE_SIZES.SMALL_DEVICE;
    return types_1.DEVICE_SIZES.EXTRA_SMALL_DEVICE;
}
exports.deviceSize = deviceSize;
// Will include styling for all sizes equal to or larger than the size
function minSize(size) {
    const sizeIndex = sizeOrder.indexOf(size);
    const sizeArr = sizeOrder.slice(sizeIndex);
    return sizeArr.join('+');
}
exports.minSize = minSize;
// Will include styling for all sizes equal to or smaller than the size
function maxSize(size) {
    const sizeIndex = sizeOrder.indexOf(size);
    const sizeArr = sizeOrder.slice(0, sizeIndex + 1);
    return sizeArr.join('+');
}
exports.maxSize = maxSize;
