"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maxSize = exports.minSize = exports.DEVICE_SIZES = exports.useResponsiveStyle = exports.CreateResponsiveStyle = void 0;
const react_native_1 = require("react-native");
const useResponsiveStyle_1 = require("./useResponsiveStyle");
exports.useResponsiveStyle = useResponsiveStyle_1.default;
const types_1 = require("./types");
Object.defineProperty(exports, "DEVICE_SIZES", { enumerable: true, get: function () { return types_1.DEVICE_SIZES; } });
const helpers_1 = require("./helpers");
Object.defineProperty(exports, "maxSize", { enumerable: true, get: function () { return helpers_1.maxSize; } });
Object.defineProperty(exports, "minSize", { enumerable: true, get: function () { return helpers_1.minSize; } });
function CreateResponsiveStyle(webStyles, styleOverrides) {
    const overrides = {};
    // Create custom style names based on the device overrides
    Object.entries(styleOverrides).forEach(([key, value]) => {
        Object.entries(value).forEach(([className, value2]) => {
            overrides[`${key}_${className}`] = value2;
        });
    });
    // Merge the stylesheets example:
    // {
    //   container: {
    //     color: 'red'
    //   },
    //   large_container: {
    //     color: 'blue'
    //   },
    //   extrasmall+small_container: {
    //     color: 'green'
    //   },
    // }
    const styles = react_native_1.StyleSheet.create(Object.assign(Object.assign({}, webStyles), overrides));
    return (0, useResponsiveStyle_1.default)(styles);
}
exports.CreateResponsiveStyle = CreateResponsiveStyle;
