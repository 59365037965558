"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const react_native_1 = require("react-native");
/**
 * Hook to watch changes in screenSize and report the dimensions whenever the device size changes
 * @param breakpoints a function that returns the device size given a width
 */
function useWindowSizes(breakpoints) {
    const [dims, setDims] = (0, react_1.useState)(() => react_native_1.Dimensions.get('window'));
    (0, react_1.useEffect)(() => {
        function handleChange({ window }) {
            // Only update the dimensions when the device size changes
            setDims((prev) => (breakpoints(prev.width) === breakpoints(window.width) ? prev : window));
        }
        const listener = react_native_1.Dimensions.addEventListener('change', handleChange);
        // We might have missed an update between calling `get` in render and
        // `addEventListener` in this handler, so we set it here. If there was
        // no change, React will filter out this update as a no-op.
        setDims(react_native_1.Dimensions.get('window'));
        return listener && listener.remove;
    }, []);
    return dims;
}
exports.default = useWindowSizes;
