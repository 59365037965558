"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEVICE_SIZES = void 0;
var DEVICE_SIZES;
(function (DEVICE_SIZES) {
    DEVICE_SIZES["EXTRA_SMALL_DEVICE"] = "extrasmall";
    DEVICE_SIZES["SMALL_DEVICE"] = "small";
    DEVICE_SIZES["MEDIUM_DEVICE"] = "medium";
    DEVICE_SIZES["LARGE_DEVICE"] = "large";
    DEVICE_SIZES["EXTRA_LARGE_DEVICE"] = "extralarge";
})(DEVICE_SIZES = exports.DEVICE_SIZES || (exports.DEVICE_SIZES = {}));
